// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-index-js": () => import("./../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-tos-js": () => import("./../src/pages/en/tos.js" /* webpackChunkName: "component---src-pages-en-tos-js" */),
  "component---src-pages-fr-cgu-js": () => import("./../src/pages/fr/cgu.js" /* webpackChunkName: "component---src-pages-fr-cgu-js" */),
  "component---src-pages-fr-index-js": () => import("./../src/pages/fr/index.js" /* webpackChunkName: "component---src-pages-fr-index-js" */),
  "component---src-pages-fr-politique-de-confidentialite-js": () => import("./../src/pages/fr/politique-de-confidentialite.js" /* webpackChunkName: "component---src-pages-fr-politique-de-confidentialite-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

